import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import GraphicStyles from '../../styles/Components/Graphic/GraphicStyles';
import OptimizedImage from '../../utils/OptimizedImage';

const Graphic = ({ component }) => {
  const { fullImage, tabletImage, mobileImage } = component;
  const isMobile = useMediaQuery('(max-width: 575px)');
  const isTablet = useMediaQuery('(min-width: 575px) and (max-width: 991px)');

  return (
    <GraphicStyles>
      <OptimizedImage
        src={
          isMobile
            ? mobileImage?.image.url
            : isTablet
            ? tabletImage?.image?.url
            : fullImage?.image?.url
        }
        alt="Shopmonkey Milestone"
        className="w-100"
      />
    </GraphicStyles>
  );
};

export default Graphic;
